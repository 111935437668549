<template>
  <div>
    <div class="page-section">
      <div class="page-section__inner gr gr--wrap">
        <div class="col col--1@tablet hide show@tablet"></div>

        <div class="col col--12 col--10@tablet page-section__card">
          <VHeading level="2" classes="page-section__card__title">
            Terms of use
          </VHeading>

          <div class="page-section__card__block__full">
            <div v-html="content" class="page-content"></div>
          </div>
        </div>
        <div class="col col--1@tablet hide show@tablet"></div>
      </div>
    </div>
  </div>
</template>

<script>
import content from '@/mixins/content';

export default {
  name: 'TermsOfUse',

  mixins: [content],

  created() {
    this.fetchContent('terms');
  }
};
</script>

<style>
.page-content p {
  margin: 20px 0 !important;
}
.page-content h1, h2, h3, h4, h5 {
  margin: 30px 0 !important;
}
</style>